<template>
  <div class="point">
    <div class="header">
      <div class="title">
        <div class="leftFont">
          <h3>{{$t('User.Account.Pages.MyBalance')}}</h3>
          <!-- <p>（可用于购买商品）</p> -->
        </div>
        <div class="rightFont">
          <p><i class="iconfont icon-wenhao"></i> {{$t('Member.Common.BalanceExp')}}</p>
        </div>
      </div>
      <div class="pointInfo">
        <h1 class="num">{{ balance.balance }}</h1>
        <div class="decripe">
          <p class="explain">{{$t('Member.Common.AllBalance')}}</p>
        </div>
      </div>
    </div>

    <div class="list" ref="list">
      <h3 class="title">{{$t('Member.Common.BalanceDetil')}}</h3>
      <table class="pointlist">
        <thead>
          <tr>
           <th>{{$t('Common.NavTitle.Time')}}</th>
            <th>{{$t('Common.NavTitle.Detail')}} <i class="iconfont icon-jiantou-copy-copy"></i></th>
            <th>{{$t('Common.NavTitle.Explain')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in balance.list" :key="item.id">
            <td class="time">{{ item.createtime }}</td>
            <td class="num">
              {{
                item.change_balance.startsWith("-")
                  ? item.change_balance
                  : "+" + item.change_balance
              }}
            </td>
            <td class="detil">{{ item.reason }}</td>
          </tr>
        </tbody>
      </table>
      <empty
        :decripe="$t('Member.Common.NoBalance')"
        v-if="balance.list && balance.list.length == 0"
      ></empty>
      <!-- 分页 -->
      <div class="pagination">
        <pagination
          :totalPage="balance.paginated ? balance.paginated.pages : 1"
          v-model="currentPage"
          @changePage="changePage"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "common/pagination/Pagination";
import User from "@/model/User.js";
import Empty from "common/empty/Empty";
export default {
  name: "MemberBalance",
  data() {
    return {
      //积分数据
      balance: {},

      currentPage: 1,
    };
  },
  created() {
    this.getBalanceList();
  },
  components: {
    Pagination,
    Empty,
  },
  methods: {
    //   获取列表
    getBalanceList() {
      let loading;

      this.$nextTick(() => {
        loading = this.$loading(this.$refs.list);
      });
      User.Balance.List({
        data: {
          page: this.currentPage,
          limit: 10,
          type: "active",
        },
        succ: (res) => {
          this.balance = res;
          loading.close();
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
          loading.close();
        },
        
      });
    },

    //切换页码
    changePage() {
      this.getBalanceList();
    },
  },
};
</script>
<style lang='scss' scoped>

.point {

  .header {
    padding: 1rem;
    background-color: white;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leftFont {
        color: var(--text-color-title);
        display: flex;
        align-items: center;
        h3 {
          margin-right: 0.5rem;
          font-size: 1.25rem;
        }
        p {
          color: var(--text-color-default);
          font-size: 0.75rem;
        }
      }

      .rightFont {
        color: var(--text-color-default);
        font-size: 0.75rem;
        p {
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .pointInfo {
      margin-top: 0.75rem;
      display: flex;
      .num {
        font-size: 3rem;
        color: var(--red-color);
      }

      .decripe {
        font-size: 0.75rem;
        color: var(--text-color-default);
        margin-top: 0.75rem;
        margin-left: 0.75rem;
      }
    }
  }

  .list {
      min-height: 400px;
    position: relative;
    margin-top: 1rem;
    background-color: white;
    padding: 1rem;
    font-size: 0.75rem;
    color: var(--text-color-default);
    .title {
      font-size: 1rem;
      color: var(--text-color-title);
      margin-bottom: 1rem;
    }
    .pointlist {
      border-collapse: collapse;
      width: 100%;
      th {
        padding: 0.75rem 0;
        background-color: var(--border-color-default);
      }
      td {
        padding: 0.75rem 0;
        border-bottom: 1px solid var(--border-color-default);
        text-align: center;
      }

      .num {
        color: var(--red-color);
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 1.5rem;
    }
  }
}
</style>